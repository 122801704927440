export const ROUTE_NAV = Object.freeze({
  index: '',
  onboarding: '/onboarding',
  onboardingGender: '/onboarding/gender',
  onboardingFemale: '/onboarding/female',
  onboardingFemaleChapterGoal: '/onboarding/female/chapter-goal',
  onboardingFemaleMotivation: '/onboarding/female/motivation',
  onboardingFemaleMainGoal: '/onboarding/female/main-goal',
  onboardingFemaleGoalInfo: '/onboarding/female/goal-info',
  onboardingFemaleAreYouMother: '/onboarding/female/are-your-mother',
  onboardingFemaleChildAge: '/onboarding/female/child-age',
  onboardingFemaleCanYouGoToGym: '/onboarding/female/can-you-go-to-gym',
  onboardingFemalePreFocusArea: '/onboarding/female/pre-focus-area',
  onboardingFemaleFocusArea: '/onboarding/female/focus-area',
  onboardingFemaleSpecialEvent: '/onboarding/female/special-event',
  onboardingFemaleSpecialEventInfo: '/onboarding/female/special-event-info',
  onboardingFemaleWeCanMakeIt: '/onboarding/female/we-can-make-it',
  onboardingFemaleChapterBodyData: '/onboarding/female/chapter-body-data',
  onboardingFemaleBirthday: '/onboarding/female/birthday',
  onboardingFemaleHeight: '/onboarding/female/height',
  onboardingFemaleCurrentWeight: '/onboarding/female/current-weight',
  onboardingFemaleTargetWeight: '/onboarding/female/target-weight',
  onboardingFemaleTargetDate: '/onboarding/female/target-date',
  onboardingFemaleCurrentBodyShape: '/onboarding/female/current-body-shape',
  onboardingFemaleTargetBodyShape: '/onboarding/female/target-body-shape',
  onboardingFemaleBodyShapeInfo: '/onboarding/female/body-shape-info',
  onboardingFemaleFeelUnsatisfied: '/onboarding/female/feel-unsatisfied',
  onboardingFemalePickUpSuitableWorkouts: '/onboarding/female/pick-up-suitable-workouts',
  onboardingFemaleEasilyGiveUp: '/onboarding/female/easily-give-up',
  onboardingFemaleChapterFitnessAnalysis: '/onboarding/female/chapter-fitness-analysis',
  onboardingFemaleActivityLevel: '/onboarding/female/activity-level',
  onboardingFemaleFitnessLevel: '/onboarding/female/fitness-level',
  onboardingFemaleFlexibility: '/onboarding/female/flexibility',
  onboardingFemaleStoppingYourGoal: '/onboarding/female/stopping-your-goal',
  onboardingFemaleStoppingYourGoalInfo: '/onboarding/female/stopping-your-goal-info',
  onboardingFemalePreferWorkout: '/onboarding/female/prefer-workout',
  onboardingFemalePreferredWorkoutType: '/onboarding/female/preferred-workout-type',
  onboardingFemaleSufferedAnyDiscomfort: '/onboarding/female/suffered-any-discomfort',
  onboardingFemalePreferredWorkoutLevel: '/onboarding/female/preferred-workout-level',
  onboardingFemaleHowOftenWorkout: '/onboarding/female/how-often-workout',
  onboardingFemaleHowWillCelebrate: '/onboarding/female/how-will-celebrate',
  onboardingFemaleFasterResult: '/onboarding/female/faster-result',
  onboardingFemaleNewDate: '/onboarding/female/new-date',
  onboardingFemaleSocialProof: '/onboarding/female/social-proof',
  onboardingFemaleMotivationPhoto1: '/onboarding/female/motivation-photo-1',
  onboardingFemaleMotivationPhoto2: '/onboarding/female/motivation-photo-2',
  onboardingFemaleMotivationPhoto3: '/onboarding/female/motivation-photo-3',
  onboardingFemaleGeneratePlan: '/onboarding/female/generate-plan',
  onboardingFemalePersonalPlan: '/onboarding/female/personal-plan',
  onboardingFemaleCheckout: '/onboarding/female/checkout',
  onboardingFemalePayment: '/onboarding/female/payment',
  onboardingFemaleSubscribeForEarlyBirds: '/onboarding/female/subscribe-for-early-birds',
  onboardingMale: '/onboarding/male',
  onboardingMaleGoal: '/onboarding/male/goal',
})

export const ROUTE_NAV_APP = Object.freeze({
  feed: '/app/feed',
  analytics: '/app/analytics',
  community: '/app/community',
  workout: '/app/workout',
  planDetails: '/app/plan-details',
  planSchedule: '/app/plan-schedule',
  planSettings: '/app/plan-settings',
  planSettingsProfile: '/app/plan-settings-profile',
  settings: '/app/settings',
})
