// Add this import statement

export default defineNuxtPlugin(() => {
  return {
    provide: {
      analytics: {
        event(name: string, props?: object) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          if (window.amplitude) window.amplitude.track(name, props)
          console.log('analytics.event', name, props)
        },
        userProps(props: object) {
          console.log('analytics.userProps', props)
        },
      },
    },
  }
})
