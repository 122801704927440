<script setup lang="ts">
const { initialize } = useHotjar()
onMounted(() => initialize())
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
