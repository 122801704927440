import { useScriptMetaPixel, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const metaPixel = useScriptMetaPixel({"id":"848628657244982","scriptOptions":{"bundle":true,"trigger":"client"}})
    return { provide: { $scripts: { metaPixel } } }
  }
})